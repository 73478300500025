.card {
    width: 200px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 0 5px #00000020;
    cursor: pointer;
    margin: 0 20px 20px 0;
    overflow: hidden;
    position: relative;
}

.card:hover {
    box-shadow: 0 0 10px #00000040;
}

.disabledCard {
    width: 200px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 0 5px #00000020;
    margin: 10px;
    overflow: hidden;
    opacity: 0.3;
}

#cardName {
    margin-top: 20px;
    text-align: center;
}

#flip-button {
    background-image: url(../../../images/qr-code.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 50% 50%;
    background-color: #fff;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #4285f4;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid #dadce0;
}

#delete-button {
    background-image: url(../../../images/delete.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 50% 50%;
    background-color: #fff;
    position: absolute;
    left: 10px;
    top: 10px;
    color: #4285f4;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid #dadce0;
}

#back-button {
    background-image: url(../../../images/back.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 50% 50%;
    background-color: #fff;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #4285f4;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid #dadce0;
}

#flip-button:hover, #back-button:hover, #delete-button:hover {
    box-shadow: 0 0 10px #00000040;
    cursor: pointer;
}
button {
    background-color: #fff;
    white-space: nowrap;
    bottom: 132px;
    padding: 0px 16px 0px 16px;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 24px;
    color: #4285f4;
    height: 40px;
    line-height: 40px;
    border-radius: 18px;
    border: 1px solid #dadce0;
    display: flex;
    justify-content: center;
    align-items: center;
}

button:active {
    background-color: #e8eaed;
}

button:focus {
    outline: none;
}

button:focus-visible {
    outline: 1px solid #4285f4;
}
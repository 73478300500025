.dropdownWrapper {
    width: 200px;
    position: relative;
    display: inline-block;
}

.dropdownContent {
    width: 100%;
    background: white;
    position: absolute;
    max-height: 150px;
    overflow-y: scroll;
    z-index: 1;
}

#dropdownButton {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    font-size: 16px;
    border: 2px solid;
    cursor: pointer;
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#dropdownLabel {
    flex: 1 1 auto;
    text-decoration: none;
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

#dropdownRecord {
    padding-left: 10px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: text-bottom;
    z-index: 2;
    font-size: 16px;
}

#dropdownRecord:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
}


* {
    margin:   0;
    padding:  0;
    box-sizing: border-box;
}

.wrapper {
    display:    flex;
    flex-direction: column;
    align-items:  center;
    align-content:  center;
    width:      100vw;
    height:     100vh;
    padding:    10px;
}

#model-viewer {
    width: 100%;
    height: 100%;
}

#ar-button {
    background-image: url(../../images/ar_button.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 12px 50%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    bottom: 132px;
    padding: 0px 16px 0px 40px;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    color: #4285f4;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #dadce0;
    width: 200px;
}

#ar-button:active {
    background-color: #e8eaed;
}

#ar-button:focus {
    outline: none;
}

#ar-button:focus-visible {
    outline: 1px solid #4285f4;
}